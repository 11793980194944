import palette from "../palette";

export default {
    styleOverrides: {
        root: {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
        },

    }
}
